// 'text-success': stock.quantity >= stock.stock_min,

<template>
  <span
    class="app_sp_stock_li"
    :class="{
      'text-danger': stock.quantity < stock.stock_min
    }"
  >
    <span>{{ stock.quantity }}</span>
    <span v-show="stock.stock_min > 0">/{{stock.stock_min}}</span>
    <a
      v-if="config.can_transfer"
      href="#"
      @click.prevent="$emit('clickTransfer')"
      class="ml-1 btn btn-sm btn-light"
    >
      <i class="fas fa-exchange-alt"></i>
    </a>
    <a
      v-if="config.can_use"
      href="#"
      class="btn btn-light btn-sm"
      @click.prevent="$emit('clickUse')"
    >
      <i class="fas fa-arrow-right"></i>
    </a>
  </span>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
    ...mapState({
      stores: s => s.logistic.stores,
      config: s => s.config.logistic.stock
    }),
  },
  props: {
    stock: {
      validator: val => {
        return val.quantity != undefined && val.stock_min != undefined;
      }
    },
    store_id: {}
  }
};
</script>

<style>
.app_sp_stock_li {
  display: inline;
}
</style>
