var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h4',[_vm._v("STOCK")]),_c('app-table-registers',{ref:"tr",attrs:{"getList":_vm.getList}},[_c('template',{slot:"tl"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"btn-group"})])]),_c('template',{slot:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("COD.")]),_c('th'),_c('th',[_vm._v("PRODUCTO")]),_vm._l((_vm.stores),function(l){return _c('th',{key:l.id},[_vm._v(_vm._s(l.name))])}),_c('th',{directives:[{name:"show",rawName:"v-show",value:(_vm.config.see_total),expression:"config.see_total"}]},[_vm._v("TOTAL")])],2)]),_c('tbody',_vm._l((_vm.list.data),function(l){return _c('tr',{key:l.id},[_c('td',[_vm._v(_vm._s(l.product_id))]),_c('td',[(l.product_file_id)?_c('app-img-viewer',{attrs:{"fileId":l.product_file_id}}):_vm._e()],1),_c('td',[_c('div',[_vm._v(_vm._s(l.product_name))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(l.brand_name),expression:"l.brand_name"}],staticClass:"badge badge-light"},[_vm._v(_vm._s(l.brand_name)+" ")])]),_vm._l((_vm.stores),function(l1){return _c('td',{key:l1.id},[(l.stocks[l1.id])?_c('SpanStock',{attrs:{"stock":l.stocks[l1.id]},on:{"clickTransfer":function($event){_vm.$refs.dFormTransGoods.show();
                    _vm.$refs.formTransGood.loadStock(l, l1.id);},"clickUse":function($event){_vm.$refs.dFormUse.show();
                    _vm.$refs.formUse.loadStock(l, l1.id);}}}):_c('span',[_vm._v("0")])],1)}),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.config.see_total),expression:"config.see_total"}]},[_c('span',[_vm._v(_vm._s(l.quantity))]),(_vm.config.can_reconfig_stock)?_c('button',{staticClass:"btn btn-light btn-sm ml-2",on:{"click":function($event){_vm.$refs.dConfSto.show();
                    _vm.$refs.confStock.loadReg(l);}}},[_c('i',{staticClass:"fa fa-cog"})]):_vm._e()])],2)}),0)])],2)],1)]),_c('app-modal-basic',{ref:"dFormStore"},[_c('FormStore',{ref:"formStore",on:{"submitted":function($event){return _vm.$refs.dFormStore.hide()}}})],1),_c('app-modal-basic',{ref:"dFormAddStock"},[_c('FormAddStock',{ref:"formToAdd",on:{"submitted":function($event){_vm.$refs.dFormAddStock.hide();
        _vm.$refs.tr.gl();}}})],1),_c('app-modal-basic',{ref:"dFormTransGoods"},[_c('FormTransGood',{ref:"formTransGood",on:{"submitted":function($event){_vm.$refs.dFormTransGoods.hide();
        _vm.$refs.tr.gl();}}})],1),_c('app-modal-basic',{ref:"dConfSto"},[_c('FormConfigureStock',{ref:"confStock",attrs:{"stores":_vm.stores},on:{"submitted":function($event){_vm.$refs.tr.gl();
        _vm.$refs.dConfSto.hide();}}})],1),_c('app-modal-basic',{ref:"dFormUse"},[_c('FormUse',{ref:"formUse",on:{"submitted":function($event){_vm.$refs.tr.gl();
        _vm.$refs.dFormUse.hide();}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }