<template>
  <form @submit.prevent="save()">
    <div class="form-group">
      <label for="">Producto</label>
      <p class="form-control disabled" disabled>{{ reg.product_name }} </p>
    </div>
    <div class="form-group" v-for="(l, index) in reg.stock" :key="index">
      <label for="">{{ stores.filter(x => x.id == index)[0].name }} </label>
      <input type="number" class="form-control" :value="l" @change="reg.stock[index] = $event.target.value">
    </div>
    <app-button-submit></app-button-submit>
  </form>
</template>

<script>
import { LogisticService } from '../service';
export default {
  data: () => ({
    reg: {}
  }),
  props: {
    stores: {}
  },
  methods: {
    save() {
      LogisticService.saveReconfigStock(this.reg).then(
        res => this.$emit('submitted', res)
      );
    },
    loadReg(reg) {
      this.reg = reg;
    }
  }
}
</script>

<style>

</style>
