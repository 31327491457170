<template>
  <form @submit.prevent="save();" ref="form">
    <div class="form-group">
      <label for="">Almacen</label>
      <SelectStore ref="selSto" v-model="reg.store_id"></SelectStore>
      <app-small-form-errors :errors="errors.store_id"></app-small-form-errors>
    </div>
    <div class="form-group">
      <label for="">Producto</label>
      <SelectProduct ref="selPro" typeId="1" v-model="reg.product_id"></SelectProduct>
      <app-small-form-errors :errors="errors.product_id"></app-small-form-errors>
    </div>
    <div class="form-group">
      <label for="">Cantidad</label>
      <app-input-number v-model="reg.quantity"></app-input-number>
      <app-small-form-errors :errors="errors.quantity"></app-small-form-errors>
    </div>
    <div class="form-group">
      <label for="">Stock Minimo</label>
      <app-input-number v-model="reg.stock_min"></app-input-number>
      <app-small-form-errors :errors="errors.stock_min"></app-small-form-errors>
    </div>
    <app-button-submit :disabled="errors"></app-button-submit>
  </form>
</template>

<script>
import SelectStore from '../stores/Select';
import validate from 'validate.js';
import SelectProduct from '../../store-module/products/Select';
import { LogisticService } from '../service';

const formRules = {
  store_id: {presence: {message: 'Requerido'}},
  product_id: {presence: {message: 'Requerido'}},
  quantity: {presence: {message: 'Requerido'}},
  stock_min: {presence: {message: 'Requerido'}},
}

export default {
  components: {
    SelectStore,
    SelectProduct,
  },
  data: () => ({
    reg: {}
  }),
  computed: {
    errors() {
      let err = validate(this.reg, formRules, { fullMessages: false });
      return err ? err : false;
    }
  },
  methods: {
    save() {
      LogisticService.addStock(this.reg).then(
        res => (this.$emit('submitted', res))
      )
    },
    reset() {
      this.reg = {
        stock_min: 0
      };
      this.$refs.selPro.reset();
      // this.$refs.form.reset();
    }
  }
}
</script>

<style>

</style>
