<template>
  <form @submit.prevent="save()">
    <div class="form-group">
      <label for>Producto</label>
      <SelectProduct v-model="reg.product_id" :disabled="true" ref="selPro">
        <app-small-form-errors :errors="errors.product_id"></app-small-form-errors>
      </SelectProduct>
    </div>
    <div class="form-group">
      <label for>Fuente</label>
      <SelectStore ref="selStoSource" v-model="origin_store_id" disabled></SelectStore>
      <app-small-form-errors :errors="errors.origin_store_id"></app-small-form-errors>
    </div>
    <!-- <div class="form-group">
      <label for>Destino</label>
      <SelectStore ref="selStoTarget" v-model="reg.store_id"></SelectStore>
      <app-small-form-errors :errors="errors.store_id"></app-small-form-errors>
    </div> -->
    <div class="form-group">
      <label for>Cantidad Utilizada</label>
      <input type="number" v-model="reg.quantity" :max="reg.stock" class="form-control">
      <app-small-form-errors :errors="errors.quantity"></app-small-form-errors>
    </div>
    <app-button-submit :disabled="errors">Guardar</app-button-submit>
  </form>
</template>
<script>
import SelectProduct from "../../store-module/products/Select";
import SelectStore from "../stores/Select";
import validate from "validate.js";
import { LogisticService } from "../service";

const formRules = function(reg) {
  return {
    product_id: { presence: { message: "Requerido" } },
    origin_store_id: { presence: { message: "Requerido" } },
    // store_id: {
    //   presence: { message: "Requerido" },
    //   exclusion: {
    //     within: [reg.origin_store_id],
    //     message: "no puedes usar ese destino"
    //   }
    // },
    quantity: {
      presence: { message: "Requerido" },
      numericality: {
        onlyInteger: true,
        greaterThan: 0,
        lessThanOrEqualTo: reg.stock,
        notGreaterThan: "Debe ser mayor a 0",
        notLessThanOrEqualTo:
          "Debe ser menor a la cantidad actual " + reg.stock,
        notInteger: "No es entero"
      }
    }
  };
};

export default {
  components:{
    SelectProduct,
    SelectStore
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    reg: {},
    origin_store_id: undefined,
    stock: {}
  }),

  computed: {
    errors() {
      let err = validate(this.reg, formRules(this.reg), {
        fullMessages: false
      });
      return err ? err : false;
    }
  },

  watch: {
    origin_store_id(to) {
      this.reg.origin_store_id = to;
    }
  },

  mounted () {
    //
  },

  methods: {
    loadStock(reg, store_id) {
      this.reset();
      this.stock = reg.stock[store_id];
      this.reg = { quantity: 1, stock: this.stock, origin_store_id: store_id };
      this.$refs.selStoSource.setValueFromId(store_id);
      // this.$refs.selStoTarget.reset();
      this.origin_store_id = store_id;
      this.$refs.selPro.setValueFromId(reg.product_id);
    },
    reset() {
      this.reg = {};
      this.stock = {};
      this.store_id = undefined;
    },
    save() {
      LogisticService.useStock({
        product_id: this.reg.product_id,
        store_id: this.origin_store_id,
        quantity: this.reg.quantity
      }).then(() => this.$emit("submitted"));
    }
  }

}
</script>

<style></style>
